import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { Subscription } from 'rxjs';
import { LayoutService } from './core/services/layout.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from './features/authentication/authentication-service/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Lomah';
  username = '';
  password = '';
  isNumber = false;
  showPassword: boolean = false;
  showingLangs = [];
  errorMessage: string = '';
  selectedLang = 'en';
  errMessage = '';
  subscriptions: Subscription[] = [];
  constructor(
    private router: Router,
    private message: NzMessageService,
    private authService: AuthenticationService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private layoutService: LayoutService
  ) {
    this.subscriptions.push(
      this.layoutService.ErrorMessage.subscribe((err) => {
        if (err) {
          this.errMessage = err;
          this.message.error(this.errMessage, {
            nzDuration: 5000,
          });
        }
      })
    );

    this.subscriptions.push(
      this.layoutService.editUserName.subscribe((res) => {
        if (res) {
          this.username = localStorage.getItem('username');
          this.selectedLang = localStorage.getItem('selectedLanguage');
          this.isNumber = false;
        }
      })
    );
    
    if (
      localStorage.getItem('selectedLanguage') &&
      localStorage.getItem('username')
    ) {
      this.isNumber = true;
    }else{
      this.isNumber = false;
    }

    this.translate.addLangs(['en', 'ur']);
    this.showingLangs = translate.getLangs();
  }

  ngOnInit(): void {
  }

  SubmitPakNumber() {
    if (this.username && this.password && this.selectedLang) {
      this.spinner.show('checking');
      this.layoutService.checkUserName(this.username).subscribe((res) => {
        if (res.status == 'ok') {

          if (res.authenticated) {
            if (res.userStatus == true) {
              // this.username = '';
              // this.selectedLang = '';

              const payload = {
                userName: this.username,
                password: this.password,
              }

              this.Login(payload);

            } else if (res.userStatus == false) {
              this.message.error('User is blocked, Try another user!', {
                nzDuration: 7000,
              });
              this.isNumber = false;
              this.spinner.hide('checking');

              return;
            } else {
              this.message.error('User is deleted, Try another user!', {
                nzDuration: 7000,
              });

              this.isNumber = false;
              this.spinner.hide('checking');
              return;
            }
          } else {
            this.message.error('Invalid username, Try again!', {
              nzDuration: 7000,
            });
            this.isNumber = false;
            this.spinner.hide('checking');
          }
        } else {
          this.spinner.hide('checking');
          this.isNumber = false;
          if (res && res.msg) {
            this.message.error(res.msg, {
              nzDuration: 7000,
            });
          }
        }
      });
    }
  }

  Login(payload) {
    if (this.password !== '') {
      this.spinner.show('loginLoading');
      this.authService.loginUser(payload).subscribe(
        (result) => {
          if (result.status == 'ok') {
            if (result.token) {
              this.authService
                .getPermissionsAgainstUser(
                  this.username,
                  result.token
                )
                .subscribe((response) => {
                  if (response.status == 'ok') {
                    this.spinner.hide('checking');
                    localStorage.setItem('username', this.username);
                    this.layoutService.selectedLanguage.next(this.selectedLang);
                    localStorage.setItem('selectedLanguage', this.selectedLang);
                    this.translate.use(this.selectedLang);
                    this.isNumber = true;
                    this.layoutService.changeInSessionStorageUserName.next(
                      true
                    );
                    localStorage.setItem('userType', response.user.user_type);
                    localStorage.setItem('userRole', response.user.role);
                    this.authService.currentUserRole = response.user.role;
                    if (response.user.first_name)
                    localStorage.setItem(
                        'firstName',
                        response.user.first_name
                      );
                    if (response.user.last_name)
                    localStorage.setItem(
                        'lastName',
                        response.user.last_name
                      );

                    if (response.permissions && response.permissions.length) {
                      let encryptedData = this.layoutService.encryptData(
                        response.permissions
                      );

                      localStorage.setItem('perms', encryptedData);

                      localStorage.setItem('currentUser_LOMAH', result.token);
                      this.router.navigate(['pages/dashboard']);
                      this.spinner.hide('loginLoading');
                    } else {
                      this.message.error(
                        'No role assigned to user ' +
                        localStorage.getItem('pkNumber'),
                        {
                          nzDuration: 5000,
                        }
                      );
                      this.layoutService.selectedLanguage.next(this.selectedLang);
                      localStorage.setItem('selectedLanguage', this.selectedLang);
                      this.translate.use(this.selectedLang);
                      this.spinner.hide('loginLoading');
                      this.router.navigate(['access-forbidden']);
                      return;
                    }
                  } else {
                    if (this.errorMessage == '') {
                      this.message.error(
                        'No permissions found against user ' +
                        localStorage.getItem('pkNumber') +
                          '. Refresh page   try again!',
                        {
                          nzDuration: 5000,
                        }
                      );
                    }
                    this.layoutService.selectedLanguage.next(this.selectedLang);
                    localStorage.setItem('selectedLanguage', this.selectedLang);
                    this.translate.use(this.selectedLang);
                    this.spinner.hide('loginLoading');
                    this.spinner.hide('checking');
                    // this.router.navigate(['enter-username']);

                    return;
                  }
                });
            }
          } else {
            this.message.error(result.errorMsg, {
              nzDuration: 5000,
            });
            this.spinner.hide('loginLoading');
            this.spinner.hide('checking');
            this.layoutService.selectedLanguage.next(this.selectedLang);
            localStorage.setItem('selectedLanguage', this.selectedLang);
            this.translate.use(this.selectedLang);
            // this.router.navigate(['enter-username']);
            return;
          }
        },
        (error) => {
          this.spinner.hide('loginLoading');
          this.spinner.hide('checking');
          this.layoutService.selectedLanguage.next(this.selectedLang);
          localStorage.setItem('selectedLanguage', this.selectedLang);
          this.translate.use(this.selectedLang);
          // this.router.navigate(['enter-username']);
          return;
        }
      );
    }
  }

  ngOnDestroy() {
    this.layoutService.ErrorMessage.next('');
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
