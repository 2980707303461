<ngx-spinner
  name="langLoading"
  bdColor="rgba(0, 0, 0, 0.5)"
  size="medium"
  color="#fff"
  type="timer"
  [fullScreen]="true"
></ngx-spinner>

<header
  class="clearfix"
  [attr.nav-layout]="navLayout"
  [attr.navbar-type]="toggleStatus ? defaultNavbar : toggleNavbar"
  [attr.effect-type]="navbarEffect"
  [attr.header-theme]="headerColorTheme"
  [attr.collapsed-left-header]="collapsedLeftHeader"
  [attr.device-type]="deviceType"
>
  <div class="lfd-header-wrapper clearfix">
    <div class="show-mobile-view">
      <span>
        <img
       src="assets/logo_dark.png"
       alt="Range Management System"
       style="max-width: 300px"
     /></span>
    </div>
    <div class="lfd-header-navbar">
      <div class="lfd-left-header clearfix">
        <i
          nz-icon
          nzType="menu"
          nzTheme="outline"
          (click)="changeTheLeftPanelStatus()"
          *ngIf="
            !(
              _router.url.includes('dashboard') || _router.url.includes('login')
            )
          "
        ></i>
        <span class="hide-mobile-view" translate>
          <img
            src="assets/logo_dark.png"
            alt="Range Management System"
            style="max-width: 300px"
          />
        </span>
      </div>

      <div class="lfd-right-header clearfix">
        <ul>
          <!-- <nz-select
            *ngIf="!_router.url.includes('login')"
            name="selectedDashboard"
            [(ngModel)]="selectedDashboard"
            class="header-dropdown dashboard-dropdown mr-3"
            (ngModelChange)="changeDashboard($event)"
          >
            <nz-option
            *ngFor="let dashboard of availableDashboards"
            [nzValue]="dashboard"
            [nzLabel]="dashboard"
            ></nz-option>
          </nz-select> -->

          <nz-select
            name="selectedLang"
            [(ngModel)]="selectedLang"
            class="header-dropdown mr-3"
            (ngModelChange)="changeLang()"
            *ngIf="showingLangs && showingLangs.length"
          >
            <nz-option
              *ngFor="let lang of showingLangs"
              [nzValue]="lang"
              [nzLabel]="lang"
            ></nz-option>
          </nz-select>

          <!-- <li *ngIf="!_router.url.includes('login')" translate>Welcome</li> -->
          <li *ngIf="!_router.url.includes('login')">
            <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" class="d-flex">
              <div>
                <p *ngIf="!_router.url.includes('login')" class="mb-0">Welcome</p>
                <strong>{{ userName }} &nbsp;</strong>
              </div>
              <img
            src="../../../assets/images/avatar-dummy.png"
            alt=""
            style="max-width: 40px; max-height: 40px;"
          />
              <span><i nz-icon nzType="down" class="profile-avatar"></i></span>
            </a>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item (click)="gotoRoute('/pages/user-profile')">
                  <i nz-icon nzType="profile" nzTheme="outline"></i>
                  <span translate>Profile</span>
                </li>
                <li nz-menu-item *ngIf="!_router.url.includes('login') && _router.url.includes('config')" (click)="gotoRoute('/pages/dashboard')">
                  <i nz-icon nzType="database" nzTheme="outline"></i>
                  <span translate>Management Dashboard</span>
                </li>
                <li nz-menu-item *ngIf="!_router.url.includes('login') && _router.url.includes('pages')" (click)="gotoRoute('/config/dashboard')">
                  <i nz-icon nzType="setting" nzTheme="outline"></i>
                  <span translate>Configuration Dashboard</span>
                </li>
                <li
                  nz-menu-item
                  *ngIf="authService.currentUserRole === 'AD'"
                  (click)="gotoRoute('/pages/set-machine-status')"
                >
                  <i nz-icon nzType="question-circle" nzTheme="outline"></i>
                  <span translate>Machine Status</span>
                </li>
                <!-- <li nz-menu-item (click)="gotoRoute('/config')">
                  <i nz-icon nzType="setting" nzTheme="outline"></i> Config
                </li> -->
                <li nz-menu-item (click)="LogOut()">
                  <i nz-icon nzType="logout" nzTheme="outline"></i
                  ><span translate>Logout</span>
                </li>
              </ul>
            </nz-dropdown-menu>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>
